import Network from '@/services/network'

export default function handleHospitalReport() {
    const network = new Network;

    const fetchSalesReportGeneral = (query) => {
        return network.api('get', '/hospital/report/sales-report-general' + query);
    }

    const fetchSalesReportProductWise = (query) => {
        return network.api('get', '/hospital/report/sales-report-product-wise' + query);
    }

    const iPDPatientStatusReport = (query) => {
        return network.api('get', '/hospital/report/ipd-patient-status' + query);
    }

    const fetchMoneyReceiptStatement = (query) => {
        return network.api('get', '/hospital/report/money-receipt-statement' + query);
    }

    const fetchIPDSalesCollectionReport = (query) => {
        return network.api('get', '/hospital/report/ipd-sales-collection-report' + query);
    }

    return {
        fetchSalesReportGeneral,
        fetchSalesReportProductWise,
        iPDPatientStatusReport,
        fetchMoneyReceiptStatement,
        fetchIPDSalesCollectionReport
    }
}

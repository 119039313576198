import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import figureFormatter from "@/services/utils/figureFormatter";
import { useStore } from "vuex";
import useDate from '@/services/utils/day';

const { commaFormat } = figureFormatter();

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
    SulaimanLipi: {
        normal: 'https://fonts.cdnfonts.com/s/14639/solaimanlipi.woff',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
    }
}

const pdfPrinter = () => {

    const { formatDate } = useDate();
    const store = useStore();
    const { commaFormat } = figureFormatter();

    const userName = store.state.user.user.name;

    const exportToPDF = (company, data, payload) => {

        var doc = {
            pageSize: 'A4',
            pageMargins: [ 30, 100, 30, 80 ],
            pageOrientation: 'landscape',
            header: getHeader(company),
            footer: getFooter(userName),
            content: getContent(data, payload),
            styles : {
                header: {
                    fontSize: 24
                }
            },
            defaultStyle: {
                color: 'black',
                fontSize: 10,
                font: 'SulaimanLipi'
            },
            info: {
                title: 'IPD Patient Status Report'
            }
        }
        const pdfDocGenerator = pdfMake.createPdf(doc);
        pdfDocGenerator.open();
    }

    const getContent = (data, payload) => {
        return [
            {
                text: 'IPD Patient Status Report',
                style: 'header',
                alignment: 'center',
                margin: [0, 0, 20, 10],
                bold: true,
                color: 'black',
                decoration: 'underline'
            },
            {
                stack: [
                    {
                        text: `Date: ${payload.date}`
                    }
                ]
            },
            {
                style: 'tableExample',
                margin: [0, 10, 0, 0],
                table: {
                    widths: [50, 50, 50, 50, 80, 60, 70, 50, 50, 50, 50, 55],
                    body: data
                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return (rowIndex === 0) ? '#f3f2f7' : null;
                    },
                    hLineWidth: function (i, node) {
                        return 0.5;
                    },
                    vLineWidth: function (i, node) {
                        return 0.5;
                    }
                }
            },
        ]
    }

    const getHeader = (company) => {
        return {
            margin: [ 30, 25, 30, 10 ],
            columns: [
                {
                    alignment: 'left',
                    image: company.logo64,
                    maxHeight: 60,
                    maxWidth: 60
                },
                {
                    alignment: 'right',
                    stack: [
                        company.name,
                        'Address: ' + company.address,
                        'Phone: ' + company.phone,
                        'E-mail: ' + company.email
                    ]
                }
            ]
        }
    }

    const getFooter = (userName) =>{
        return {
            margin: [ 25, 0 ],
            columns: [
                {
                    columns: [
                        {
                            width: '90%',
                            alignment: 'left',
                            text: 'N.B: This is a system generated documents and requires no manual signature.',
                            margin: [5, 53, 0, 0],
                            fontSize: 8
                        }
                    ]
                },
                {
                    width: '20%',
                    alignment: 'right',
                    fontSize: 8,
                    margin: [0, 33, 0, 0],
                    stack: [
                        {
                            text: 'Printed by',
                            bold: true
                        },
                        {
                            text: userName
                        },
                        {
                            text: formatDate(new Date())
                        }
                    ]
                }
            ]
        }
    }

    const formatStatusReportData = (reportData) => {
        const { rows, totals } = getTableRow(reportData);
        return [ getTableHead(), ...rows, getTotals(reportData, totals) ];
    }

    const getTableRow = (data) => {
        const totals = {
            bill_amount: 0,
            paid_amount: 0,
            due_amount: 0
        };

        const rows = data.map((item) => {

            const billAmount = item.ledger_general && item.ledger_general.debit_sum || 0;
            const paidAmount = item.ledger_general && item.ledger_general.credit_sum || 0;
            const dueAmount = billAmount - paidAmount;

            totals.bill_amount += billAmount;
            totals.paid_amount += paidAmount;
            totals.due_amount += dueAmount;

            return [
               { text: item.admission_no },
               { text: item.admission_date },
               { text: item.service_general && item.service_general.delivery_date_time },
               { text: item.service_resource && item.service_resource.name },
               { text: item.human_resource && item.human_resource.name },
               { text: item.department && item.department.name },
               { text: `${item.patient.full_name}, ${item.patient.birthday || ''}, ${item.patient.gender}` },
               { text: item.patient.mobile_no },
               { text: item.service_general && item.service_general.completion_status || '' },
               { text: commaFormat(billAmount), alignment: 'right' },
               { text: commaFormat(paidAmount), alignment: 'right' },
               { text: commaFormat(dueAmount), alignment: 'right' }
            ]
        })

        return {
            rows,
            totals
        }
    }

    const getTotals = (data, total) => {
        return [
            { text: `Total (${data.length})`, colSpan: 6, alignment: 'right' },
            { text: '' },
            { text: '' },
            { text: '' },
            { text: '' },
            { text: '' },
            { text: '' },
            { text: '' },
            { text: '' },
            { text: commaFormat(total.bill_amount), alignment: 'right' },
            { text: commaFormat(total.paid_amount), alignment: 'right' },
            { text: commaFormat(total.due_amount), alignment: 'right' }
        ];
    }

    const getTableHead = () => {
        return [
            { text: 'Admission no' },
            { text: 'Admission date' },
            { text: 'Discharge date' },
            { text: 'Ward / Cabin no' },
            { text: 'Consultant' },
            { text: 'Admitted Department' },
            { text: 'Patient Name, Age, gender' },
            { text: 'Patient Mobile No' },
            { text: 'Status' },
            { text: 'Total Bill Amount' , alignment: 'right' },
            { text: 'Total Paid Amount' , alignment: 'right' },
            { text: 'Total Due Amount' , alignment: 'right' }
        ];
    }

    return {
        exportToPDF,
        formatStatusReportData
    }
};

export default pdfPrinter;